import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="session-checker"
export default class extends Controller {
  static targets = ["countdown"];

  connect() {
    this.remainingTime = 300; // Track the time left globally
    this.timerInterval = null;
    this.syncInterval = null;

    this.checkSessionTime(); // Initial sync with the server
    this.startSyncCheck(); // Periodic sync every minute
  }

  disconnect() {
    clearInterval(this.timerInterval);
    clearInterval(this.syncInterval);
  }

  startTimer() {
    // Clear any existing timer
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }

    this.timerInterval = setInterval(() => {
      if (this.remainingTime <= 1) {
        clearInterval(this.timerInterval);
        this.logoutUser();
        return;
      }

      this.remainingTime--;

      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;

      this.countdownTarget.textContent = this.formatTime(minutes, seconds);

      if (this.remainingTime < 300) {
        this.countdownTarget.classList.add("text-alert-500");
      } else {
        this.countdownTarget.classList.remove("text-alert-500");
      }
    }, 1000);
  }

  startSyncCheck() {
    this.syncInterval = setInterval(() => {
      this.checkSessionTime();
    }, 60000); // Sync every minute
  }

  async checkSessionTime() {
    try {
      const response = await fetch("/users/session_time.json");
      if (response.ok) {
        const data = await response.json();
        const timeLeft = data.remaining_time;

        if (timeLeft < 1) {
          this.logoutUser();
        } else {
          this.remainingTime = timeLeft; // Update the remaining time
          this.startTimer(); // Restart the timer with updated time
        }
      }
    } catch (error) {
      console.error("Session check failed:", error);
    }
  }

  logoutUser() {
    const link = document.createElement("a");
    link.href = "/users/logout";
    link.target = "_top";

    if (window.Turbo) {
      Turbo.cache.clear();
    }

    document.body.appendChild(link);
    link.click();
  }

  formatTime(minutes, seconds) {
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }
}
