import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="interaction-flow-connections"
export default class extends Controller {
  static targets = ['interactionFlow', 'reselectForm', 'previousFlow', 'interactionPrompt', 'interactionPath']

  connect() {
    // Load selected dropdowns
    this.loadDropdowns()    
  }

  async loadDropdowns() {
    if (this.hasInteractionFlowTarget && this.interactionFlowTarget.value.length > 0) {
      // Retrieve Prompts
      await this.fetchInteractionPrompts()

      // Retreive paths if prompt value found
      if (this.interactionPromptTarget.dataset.interactionPromptValue || this.interactionPromptTarget.dataset.interaction_prompt_value) {
        // Retrieve Paths
        this.fetchInteractionPaths()
      }

    }
  }

  resetDropdowns() {
    this.interactionFlowTarget.value = ''
    this.interactionPathTarget.value = ''
    this.interactionPromptTarget.value = ''
  }

  fetchInteractionPrompts() {    

    // Set Interaction Flow ID
    const selectedFlowId = this.interactionFlowTarget.value
    let selectedPrompt = null    

    if (this.interactionPromptTarget) {
      // Try to fetch either dataset attribute in camelCase or snake_case
      selectedPrompt = this.interactionPromptTarget.dataset.interactionPromptValue || this.interactionPromptTarget.dataset.interaction_prompt_value;

      // If selectedPrompt exists, normalize it to the snake_case version
      if (selectedPrompt) {
        this.interactionPromptTarget.dataset.interaction_prompt_value = selectedPrompt;
      }
    }

    // Reset drop downs
    this.interactionPathTarget.value = ''
    this.interactionPromptTarget.value = ''

    fetch(`/interaction_flows/fetch_prompts/${selectedFlowId}`)
      .then(response => response.json())
      .then(data => {
        const selectElement = this.interactionPromptTarget

        // Clear existing options
        selectElement.innerHTML = ''

        // Add a blank option at the beginning
        const blankOption = document.createElement('option')
        blankOption.value = ''
        blankOption.textContent = 'Select Prompt...'
        selectElement.appendChild(blankOption)

        // Append new options
        data.prompts.forEach(prompt => {
          const optionElement = document.createElement('option')
          optionElement.value = prompt.id
          optionElement.textContent = prompt.name
          selectElement.appendChild(optionElement)

          // Set selected option if matches selectedPrompt
          if (selectedPrompt && optionElement.value === selectedPrompt) {
            optionElement.selected = true
          }
        })

        // Check if there's a selected value to set
        if (this.interactionValueTarget && this.interactionValueTarget.value) {
          const selectedPromptValue = this.interactionValueTarget.value

          // Set the selected value
          this.interactionPromptTarget.value = selectedPromptValue
        }
      })
  }

  fetchInteractionPaths() {
    // Set Selected Prompt ID
    let selectedPromptId = this.interactionPromptTarget.value

    if (!selectedPromptId) {
      selectedPromptId = this.interactionPromptTarget.dataset.interaction_prompt_value || this.interactionPromptTarget.dataset.interactionPromptValue
    } else {
      // Update data attribute
      this.interactionPromptTarget.dataset.interaction_prompt_value = selectedPromptId
    }

    if (!selectedPromptId) {
      return
    }

    let selectedPath = null

    if (this.interactionPathTarget) {
      selectedPath = this.interactionPathTarget.dataset.interactionPathValue
    }

    fetch(`/interaction_flows/fetch_paths/${selectedPromptId}`)
      .then(response => response.json())
      .then(data => {
        const selectElement = this.interactionPathTarget
        // Clear existing options
        selectElement.innerHTML = ''

        // Add a blank option
        const blankOption = document.createElement('option')
        blankOption.value = ''
        blankOption.textContent = 'Select Path...'
        selectElement.appendChild(blankOption)

        // Iterate over each path
        data.paths.forEach(path => {
          const concatenatedNames = path.nodes.map((node, index) => {
            return (index !== 0 ? ' > ' : '') + node.name
          }).join('')

          const optionElement = document.createElement('option')
          optionElement.value = path.nodes[0].id
          optionElement.textContent = concatenatedNames
          selectElement.appendChild(optionElement)

          // Set selected option if it matches the saved selectedPath
          if (selectedPath && optionElement.value === selectedPath) {
            optionElement.selected = true
          }
        })

        // Check if there's a selected value to set
        if (this.interactionPathTarget && this.interactionPathTarget.value) {
          const selectedPathValue = this.interactionPathTarget.value

          // Set the selected value
          this.interactionPathTarget.value = selectedPathValue
        }
      })
  }

  updatePath() {
    // Set Selected Path ID
    let selectedPathId = this.interactionPathTarget.value
    // Update data attribute
    this.interactionPathTarget.dataset.interactionPathValue = selectedPathId
  }  

}