import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sms-tests"
export default class extends Controller {
  static targets = [
    "monitoringProfile",
    "recurrence",
    "monitoringDiv",
    "sendingFrequency",
  ];

  connect() {
    const checked = document.getElementById("sms_test_scheduled").checked;
    this.toggle_monitoring(checked);
    this.checkOnce();
  }

  scheduled(event) {
    this.toggle_monitoring(event.target.checked);
  }

  checkOnce() {
    const starttime = document.getElementById("sms_test_starttime_as_string");
    const endtime = document.getElementById("end_date_selector");
    const time_selectors = document.getElementById("time_selectors");
    const recurring = document.getElementById("sms_test_scheduled");

    if (this.sendingFrequencyTarget.value == 0) {
      endtime.classList.add("hidden");
      time_selectors.classList.add("hidden");
      if (recurring.checked) {
        recurring.checked = false;
        this.toggle_monitoring(false);
      }

      const start_time = new Date(starttime.value);
      const now = new Date();
      if (now < start_time) {
        return;
      }
      const dateFormatter = new Intl.DateTimeFormat("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      const timeFormatter = new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      const formatDate = (date) =>
        dateFormatter.format(date).replace(/\//g, "-"); // Replace / with -;
      const formatTime = (date) => timeFormatter.format(date);

      starttime.value = `${formatDate(now)} ${formatTime(now)}`;
    } else {
      endtime.classList.remove("hidden");
      time_selectors.classList.remove("hidden");
      if (!recurring.checked) {
        recurring.checked = true;
        this.toggle_monitoring(true);
      }
    }
  }

  toggle_monitoring(checked) {
    if (checked) {
      // If user wants it repeating show options
      this.monitoringDivTarget.classList.remove("hidden");
      if (this.sendingFrequencyTarget.value == 0) {
        this.sendingFrequencyTarget.value = 3600;
      }
    } else {
      this.monitoringProfileTarget.value = "";
      document.getElementById("sms_test_test_frequency").value = "";
      this.monitoringDivTarget.classList.add("hidden");
      this.sendingFrequencyTarget.value = 0;
    }
    this.checkOnce();
  }
}
